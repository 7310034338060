import React from 'react'
import { Permissions } from '@graphql/graphql'
import { usePermissions } from '@hooks/usePermission'

type Props = {
	children: React.ReactNode
	permission: Permissions
}

const Authorization = ({ children, permission }: Props) => {
	const { hasAcquired } = usePermissions(permission)

	if (!hasAcquired) {
		return null
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>
}

export default Authorization

export const withAuthorization = (
	WrappedComponent: React.FC<any>,
	permission: Permissions
) => {
	return (props) => {
		return (
			<Authorization permission={permission}>
				<WrappedComponent {...props} />
			</Authorization>
		)
	}
}
